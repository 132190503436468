import styled from "styled-components";

const FooterStyled = styled.footer`
    background: rgb(33, 33, 33);
    border: 1px solid rgb(33, 33, 33);
    position: fixed;
    width: 100%;
    height: auto;
    bottom: 0px;
    text-align: center;
    padding: 5px 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-family: Lato;
    z-index: 4;
`

export default FooterStyled;