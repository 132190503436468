import { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';

import ptBR from 'date-fns/locale/pt-BR';
import { API } from '../../Ajax';
import BoxSelector from '../../components/BoxSelector/BoxSelector';
import ComboBox from '../../components/ComboBox/ComboBox';
import { DateRange, ExampleCustomInput } from '../../components/DatePickerC/DatePickerC';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Section } from '../../components/Section';
import useFetchData from '../../components/useFetchData';
import { dev, std } from '../../std';
import DemographicsSection from './DemographicsSection';
import DevicesSection, { IDevices, IDevicesOnAndOff } from './DevicesSection';
import { GeneralSection } from './GeneralSection';
import HomeStyled from './HomeStyled';
import MediaSection from './MediaSection';
import OverTimeSection from './OverTimeSection';
import { IParmsDuplicate, parmsDuplicate, partial_parms } from './Sections';
const options = ["General", "Demographics", "Devices", "Over Time", "Media"]

type HomeTypes = {
  Clients: any;
}

type Props = {}



registerLocale('pt-BR', ptBR);

const ensureDevices = (d: IDevices) => {

  var devices: IDevicesOnAndOff[] = [];
  var allDevices = [
    {
      n: 'Todos Dispositivos'
    }
  ]

  if (d != undefined) {

    dev.log(d.online)
    devices = [...d.offline]
    for (let idx in d.online) {
      let el = d.online[idx]
      devices.push(el)
    }


    for (let idx in devices) {
      var el = devices[idx];
      allDevices.push(el)
    }

  }
  return { devices, allDevices };
}

export interface AnalyticsData {
  devices: any;
  movies: IMovies;
}


var obj_in_page_device: number = 0;
var obj_in_page_media: number = 0;

const useFetchAnalyticsData = () => {
  const [data, setData] = useState<AnalyticsData | null>(null);
  const devicesFetch = useFetchData<any>("Analytics/Analytics.vtt", 'get', { action: "getCloudSeemetrix", source: "seemetrix", base_url: "", user: API.user_id, method: "devices", parms: "" });
  const moviesFetch = useFetchData<IMovies>("Analytics/Analytics.vtt", 'get', { action: "getCloudSeemetrix", source: "seemetrix", base_url: "", user: API.user_id, method: "movies", parms: "" });

  useEffect(() => {
    if (devicesFetch.data && moviesFetch.data) {

      obj_in_page_device = devicesFetch.data.offline_total + devicesFetch.data.online_total
      obj_in_page_media = moviesFetch.data.used_total + moviesFetch.data.not_used_total
      setData({
        devices: devicesFetch.data,
        movies: moviesFetch.data,
      });
    }
  }, [devicesFetch.data, moviesFetch.data]);

  return data;
};

export var calendarClosed = true

export function ensureParms(arr1: IParmsDuplicate[] | IParmsDuplicate[][], dt_format: string, obj_in_page?: string) {
  partial_parms[1].value = dt_format;

  if (obj_in_page === "device") {
    partial_parms[4].value = obj_in_page_device
  }

  if (obj_in_page === "media") {
    if (obj_in_page_media != 0) {
      partial_parms[4].value = obj_in_page_media
    } else {
      partial_parms[4].value = 10
    }
  }

  let a: IParmsDuplicate[];

  if (Array.isArray(arr1[0])) {
    a = (arr1 as IParmsDuplicate[][]).reduce((acc, val) => acc.concat(val), []);
  } else {
    a = arr1 as IParmsDuplicate[];
  }

  a = a.concat(partial_parms);
  return JSON.stringify(a);
}


const Home = ({ Clients }: any) => {
  const { section = '' } = useParams<{ section: Section }>();
  const n = useNavigate();
  const data = useFetchAnalyticsData();

  //const [section, setSection] = useState("General");
  const selected = (str: string) => {
    n("/Home/" + str)
  };

  const { devices, allDevices } = ensureDevices(data?.devices)

  dev.log("Home")

  const optionsRender: { [key: string]: boolean } = {
    "General": section === "General",
    "Demographics": section === "Demographics",
    "Devices": section === "Devices",
    "Over Time": section === "Over Time",
    "Media": section === "Media",
  };

  // const [LojasLike, setLojasLike] = useState<ResponseDataP | null>(null);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const currentTime = new Date();
  const today = new Date(currentTime.getTime());
  const sevenDaysAgo: Date = new Date(currentTime.getTime() - (7 * 24 * 60 * 60 * 1000));
  // const sixMonthsAgo: Date = new Date(currentTime.setMonth(currentTime.getMonth() - 6));
  const range = new DateRange(sevenDaysAgo, today);

  const [maxDate, setMaxDate] = useState(new Date());
  const [Select, setSelect] = useState(allDevices[0].n)
  const [dateRange, setDateRange] = useState<DateRange>(range);
  const [calendarClosedCount, setCalendarClosedCount] = useState(0);

  const handleSelect = (value: string) => {
    setSelect(value)

  };
  const datePickerRef = useRef<DatePicker>(null);
  const startDate = dateRange.start;
  const endDate = dateRange.end;

  const isGeneral = optionsRender["General"]
  const isDemographics = optionsRender["Demographics"]
  const isDevices = optionsRender["Devices"]
  const isOverTime = optionsRender["Over Time"]
  const isMedia = optionsRender["Media"]

  /*
    const handleClickDiv = () => {
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true);
      }
    };*/
  const ensureDevice = () => {
    const d = devices.find((el) => el.n === Select)
    return [{ n: Select, d: d?.d || 0 }]
  }


  const parms_url = parmsDuplicate(Select === "Todos Dispositivos" ? devices : ensureDevice(), "d", "d")


  partial_parms[2] = {
    parm_name: "b",
    value: std.formatDate(dateRange.start.roundDateUp(), "/") + " " + std.formatTime(dateRange.start.roundDateUp())
  }

  if (dateRange.end != null) {
    dateRange.end.setHours(currentTime.getHours())
  } else {

    maxDate.setHours(currentTime.getHours())
    dateRange.end = maxDate
  }

  partial_parms[3] = {
    parm_name: "e",
    value: std.formatDate(dateRange.end.roundDateUp(), "/") + " " + std.formatTime(dateRange.end.roundDateUp())
  }



  const propsComponents = {
    devices_parms_url: parms_url,
    FetchAnalyticsData: data,
    calendarClosedCount: calendarClosedCount,
    DateRange: dateRange
  }

  return (
    <>

      <div className='containerMain'>
        <Header />
        <HomeStyled >

          <div className='h2'></div>
          <div className='c'>
            <div className='t'>
              PORTAL ANALYTICS
            </div>
            <div className='t2'>
              <ComboBox field='n' options={allDevices} onSelect={handleSelect} />
              <DatePicker
                ref={datePickerRef}
                locale="pt-BR"
                className="custom-datepicker"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                maxDate={maxDate}
                dateFormat="dd.MM.yy"
                withPortal
                customInput={<ExampleCustomInput ref={buttonRef} value="" onClick={() => { }} />}
                onCalendarOpen={() => (calendarClosed = false)}
                onCalendarClose={() => {
                  calendarClosed = true;
                  setCalendarClosedCount((n) => n + 1);
                }}
                onChange={(update) => {
                  if (Array.isArray(update)) {
                    const [start, end] = update;
                    if (start != null) {
                      const newMaxDate = new Date(start);
                      newMaxDate.setMonth(newMaxDate.getMonth() + 6);

                      // Garantir que maxDate nunca seja maior que hoje
                      const finalMaxDate = newMaxDate > today ? today : newMaxDate;

                      setMaxDate(finalMaxDate);
                      var range = new DateRange(start, end);
                      setDateRange(range);
                    }
                  }
                }}
                portalId="c_calendar_seemetrix"
              />
              <div className='clear' style={{ display: "none" }}>
                Clear All
              </div>
            </div>
            <BoxSelector options={options} selected={selected}
              flexDirection='row' width='100%'
              justifyContent="center"
              optionChecked={sectionIdx[section]}
            />
            {
              isGeneral &&
              <Section show={isGeneral}>
                <GeneralSection {...propsComponents} />
              </Section>
            }
            {
              isDemographics &&
              <Section show={isDemographics}>
                <DemographicsSection {...propsComponents} />
              </Section>
            }
            {
              isDevices &&
              <Section show={isDevices}>
                <DevicesSection devices={devices} {...propsComponents} />
              </Section>
            }
            {
              isOverTime &&
              <Section show={isOverTime}>
                <OverTimeSection  {...propsComponents} />
              </Section>
            }
            {
              isMedia &&
              <Section show={isMedia}>
                {data?.movies &&
                  <MediaSection {...propsComponents} movies={data.movies} />
                }
              </Section>
            }
          </div>
        </HomeStyled>

      </div>

      <Footer />
    </>
  )
}

export default Home;

const sectionIdx: Record<Section, number> = {
  "": -1,
  "General": 0,
  "Demographics": 1,
  "Devices": 2,
  "Over Time": 3,
  "Media": 4,
};
type Section = 'General' | 'Demographics' | 'Devices' | 'Over Time' | 'Media' | '';

export interface IMovies {
  not_used: any[]
  not_used_total: number;
  used: IMoviesUsed[];
  used_total: number;
}

interface IMoviesUsed {
  acl: any[]
  i: number;
  last_view: string;
  n: string;
}

