import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthenticatedRoute, checkAuthenticatedSession, isAuthenticated } from './components/Authentication';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import { dev } from './std';



const App: React.FC = () => {
  dev.log(isAuthenticated());
  dev.log(checkAuthenticatedSession());
  
  const cHome = {
    component: (parm: any) => <Home Clients={parm.data} />
  };

  return (
    <Router >
      <Routes>
        <Route path="/" element={<Login />} />;
        <Route path="/Home" element={
          <AuthenticatedRoute {...cHome}
          />
        }
        />

        <Route path="/Home/:section" element={
          <AuthenticatedRoute {...cHome} />
        }
        />

        <Route path="*" element={<Navigate to="/" />} />
        {/*
        <Route path="/Home2" element={<Home />} />;
        <Route path="/Login2" element={<Login />} />;*/}
      </Routes>
    </Router>

  );
};

export default App;


