import { Bar, Legend, LegendProps } from "recharts"
import styled from "styled-components"
import { DateRange } from "../../components/DatePickerC/DatePickerC"
import WindowComponent from "../../components/WindowComponent/WindowComponent"
import useFetchData from "../../components/useFetchData"
import spinner from '../../images/spinner.gif'
import { dev, std } from "../../std"
import { BarsG2, GraphicSections, IAges } from "./DemographicsSection"
import { calendarClosed, ensureParms } from "./Home"
import { IGenericsData, OverTimeC, PropsSection } from "./Sections"
import { API } from "../../Ajax"


type Props = {

}

type CustomLegendProps = LegendProps & {
  payload?: {
    value: any;
    id: string | number;
    type: string;
    color: string;
    inactive?: boolean;
  }[];
};

export const CustomLegend: React.FC<CustomLegendProps> = (props) => {
  const { payload } = props;

  return (
    <ul style={{ padding: 0, margin: 0, listStyleType: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap" }}>
      {
        payload?.map((entry, index) => (
          <li key={`item-${index}`} style={{ marginRight: 20, marginLeft: 20, display: "flex", alignItems: "center", gap: 6 }}>
            <div style={{
              background: entry.color,
              width: '14px',
              height: '14px'
            }}>

            </div>
            <span style={{ color: "#000" }}>{entry.value}</span>
          </li>
        ))
      }
    </ul>
  );
};



export interface IGenderAge {
  date?: string;
  kid_male: number;
  young_male: number;
  adult_male: number;
  old_male: number;
  kid_female: number;
  young_female: number;
  adult_female: number;
  old_female: number;
}


function ensureGenderAge(data: IGenericsData): IGenderAge[] {

  let GenderAgeArr: IGenderAge[] = [];

  data.o.forEach(no => {
    var values: Record<string, number> = {
      kid_male: 0,
      young_male: 0,
      adult_male: 0,
      old_male: 0,
      kid_female: 0,
      young_female: 0,
      adult_female: 0,
      old_female: 0
    }

    no.o.forEach((no) => {
      if (no.n !== undefined) {

        if (no.o.length > 0) {
          no.o.forEach((no2) => {
            if (no2.n !== undefined) {
              values[no2.n + "_" + no.n] = no2.v;
            }

          })
        }
      }
    })

    GenderAgeArr.push({
      date: std.formatDateBRL(no.n),
      kid_male: values['kid_male'],
      young_male: values['young_male'],
      adult_male: values['adult_male'],
      old_male: values['old_male'],
      kid_female: values['kid_female'],
      young_female: values['young_female'],
      adult_female: values['adult_female'],
      old_female: values['old_female']
    })
  })
  return GenderAgeArr;
}

const ensureDateFormat = (dt: DateRange): string => {
  var diffInMs:number
    if(dt.end == null){
      //const currentTime = new Date();
      var d = new Date()
      dt.end = new Date(d.getTime())
      
    }
    
    diffInMs= dt.end.getTime() - dt.start.getTime();

    const daysDifference = diffInMs / (1000 * 60 * 60 * 24);

    if (daysDifference > 30) {
      return "YYYY-MM"
    }else {
      return "YYYY-MM-DD"
    }  
  
}
    
const OverTimeSection = ({ devices_parms_url, FetchAnalyticsData, DateRange }: PropsSection) => {
  dev.log("OverTimeSection")


  var invalidKey = FetchAnalyticsData !== null && calendarClosed


  if (invalidKey) {
    dev.log("invalidKey = true DevicesSection")
  } else {
    dev.log("invalidKey = false DevicesSection")
  }

  const { data: dados, error, loading } = useFetchData<IAges>("Analytics/Analytics.vtt", 'get',
    {
      action: "getCloudSeemetrix", source: "seemetrix", user:  API.user_id,
      method: "dates/genders/ages", base_url: "statistics", parms: ensureParms(devices_parms_url, ensureDateFormat(DateRange))
    }, invalidKey);


  if (dados) {

    var GenderAgeData = ensureGenderAge(dados.data)

    OverTimeC.items[0].Component = (
      <GraphicSections<IGenderAge> data={GenderAgeData} XAxis1="date">
        <Legend content={<CustomLegend />} />
        {BarsG2.map((ar) => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} stackId={ar[3]} />)}
      </GraphicSections>
    );

  }

  return (
    <OverTimeStyled>
      <div className='c'>
        {OverTimeC.items.map((data, idx) =>
          <div className={"c_h _s_" + idx} key={"over_time_" + idx}>
            <WindowComponent.Root >
              <WindowComponent.Header>
                <WindowComponent.Title >
                  {data.Title}
                </WindowComponent.Title>
              </WindowComponent.Header>
              <WindowComponent.Content height={data.Height}>
                {loading && <div className="c_img_loading"><img className="" src={spinner} alt="" /></div>}
                {error && <div className="c_img_loading"><img className="" src={spinner} alt="" /></div>}
                {dados && data.Component}

              </WindowComponent.Content>

            </WindowComponent.Root>
          </div>
        )}
      </div>
    </OverTimeStyled>
  )
}


const OverTimeStyled = styled.div`
  > .c{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 25px;
  
    >.c_h{
      width: 100%;
    }
  }
  `

export default OverTimeSection