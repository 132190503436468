import { GridRowIdGetter, GridValidRowModel } from "@mui/x-data-grid"
import styled from "styled-components"
import Grid from "../../components/Grid/Grid"
import { GridColsDef } from "../../components/Grid/GridColsDef"
import { GroupText } from "../../components/GroupText/GroupTextRoot"
import WindowComponent from "../../components/WindowComponent/WindowComponent"
import useFetchData from "../../components/useFetchData"
import spinner from '../../images/spinner.gif'
import { dev, std } from "../../std"
import { calendarClosed, ensureParms, IMovies } from "./Home"
import {  IEmotions, IGenericsData, MediaC, parmsDuplicate, PropsSection, theme } from "./Sections"
import { API } from "../../Ajax"


const MediaStyled = styled.div`
  .c_img_loading{
    
    height: 100%;
  }
  > .c{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 25px;
  
    >.c_h{
      width: 100%;
    }
    ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width:calc(16% - 20px);
      }
  
  
    @media (max-width: 1190px) {
      ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width:calc(33.33% - 20px);
      }
      
    }
    @media (max-width: 700px) {
      ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width:100%;
      }
      
    }
  }
  `
const cols = new GridColsDef({
  default: {
    width: 100,
    align: 'left',
    headerAlign: 'left',
  },
  items: [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      width: 240,
      field: 'name',
      headerName: 'Nome',
    },
    {
      field: 'ots',
      headerName: 'OTS',
    },
    {
      field: 'watchers',
      headerName: 'Observadores',
    },
    {
      field: 'dwell_time',
      headerName: 'Tempo de permanência, seg.',
      width: 130,
    },
    {
      field: 'attention_time',
      headerName: 'Tempo de atenção, seg.',
      width: 130,
    },
    {
      field: 'last_view',
      headerName: 'Última visualização',
      width: 250,
    },
  ],
});


interface IMediaSectionProps extends PropsSection {
  movies: IMovies;
}

const MediaSection = ({ devices_parms_url, movies, FetchAnalyticsData }: IMediaSectionProps) => {
  dev.log("MediaSection")


  var invalidKey = FetchAnalyticsData !== null && calendarClosed


  if (invalidKey) {
    dev.log("invalidKey = true DevicesSection")
  } else {
    dev.log("invalidKey = false DevicesSection")
  }

  const media_parms_url = parmsDuplicate(movies.used, "m", "i")

  const { data: dados, error, loading } = useFetchData<IEmotions>("Analytics/Analytics.vtt", 'get',
    { action: "getCloudSeemetrix", source: "seemetrix", user:  API.user_id, method: "movies/emotions", base_url: "statistics", parms: ensureParms([media_parms_url, devices_parms_url], "YYYY-MM-DD HH", "media") }
  );

  if (dados) {
    var ensureTopContentAttention: string = "";
    var ensureTopContentHappiness: string = "";
    var dataGrid: CMediaSection[] = [];
    var ots_n = dados?.data?.ots || 0
    var v_n = dados?.data?.v || 0

    if (dados?.data?.o) {
      var mapPromise1 = std.groupBy<IGenericsData>(dados?.data?.o, (no) =>  no.n)
      var attention_time_ant = 0
      var happiness_time_ant = 0

      movies.used.forEach((no) => {
        var pushDataGrid: IMediaSection = { id: no.i, name: no.n, last_view: no.last_view }
        if (mapPromise1[no.i]) {
          var x = mapPromise1[no.i][0]
          pushDataGrid.ots = x.ots;
          pushDataGrid.attention_time = x.vd;
          pushDataGrid.dwell_time = x.otsd;
          pushDataGrid.watchers = x.v;

          if(x.o[1].vd > happiness_time_ant){
            happiness_time_ant = x.o[1].vd
            ensureTopContentHappiness = pushDataGrid.name
          }
          

          if (x.vd > attention_time_ant) {
            attention_time_ant = x.vd;
            ensureTopContentAttention = pushDataGrid.name
          }
        }
        dataGrid.push(new CMediaSection(pushDataGrid))
      }
      )
      const getRowId: GridRowIdGetter<GridValidRowModel> = (row) => row.id;

      MediaC.items[0].Component = <GroupText t_1={ots_n} t_2='pessoas' />
      MediaC.items[1].Component = <GroupText t_1={v_n} t_2='pessoas' />
      MediaC.items[2].Component = <GroupText t_1={ensureTopContentAttention} t_2={attention_time_ant} />
      MediaC.items[3].Component = <GroupText t_1={ensureTopContentHappiness} t_2={""} />
      MediaC.items[4].Component = <GroupText t_1={(dados.data.otsd / dados.data.ots).formatToSeconds() + " segundos"} t_2={"Por Visualização"} />
      MediaC.items[5].Component = <GroupText t_1={(dados.data.vd / dados.data.v).formatToSeconds() + " segundos"} t_2={"Por Visualização"} />
      MediaC.items[6].Component =
        <div style={{ marginTop: "25px" }}>
          <Grid themeProps={theme} getRowId={getRowId}
            columns={cols.getItems()} rows={dataGrid} />
        </div>
    }
  }

  return (
    <MediaStyled>
      <div className='c'>
        {MediaC.items.map((data, idx) =>
          <div className={"c_h _s_" + idx} key={"media_" + idx}>
            <WindowComponent.Root >
              <WindowComponent.Header>
                <WindowComponent.Title >
                  {data.Title}
                </WindowComponent.Title>
              </WindowComponent.Header>
              <WindowComponent.Content  minHeight={data.Height}>
                {loading && <div className="c_img_loading"><img className="" src={spinner} alt="" /></div>}
                {error && <div className="c_img_loading"><img className="" src={spinner} alt="" /></div>}
                {dados && data.Component }
              </WindowComponent.Content>

            </WindowComponent.Root>
          </div>
        )}
      </div>
    </MediaStyled>
  )
}

export default MediaSection


interface IMediaSection {
  id: number | string;
  name: string;
  ots?: number;
  watchers?: number;
  dwell_time?: number | string;
  attention_time?: number;
  last_view: string;
}

class CMediaSection implements IMediaSection {
  id: number | string;
  name: string;
  ots?: number;
  watchers?: number;
  dwell_time?: number | string;;
  attention_time?: number;
  last_view: string;

  constructor(data: IMediaSection) {
    this.id = data.id;
    this.name = data.name;
    this.ots = data.ots || 0;
    this.watchers = data.watchers || 0;
    this.dwell_time = data.dwell_time || 0;
    this.attention_time = data.attention_time || 0;
    this.last_view = data.last_view;
  }
}



